import { isMobile } from '@/lib/isMobile';
import { useCurrentAuctionQuery } from '@/queries/auction';
import { AuctionCycleSchema } from '@/schemas/auction';
import { useAccountStore } from '@/stores/accountStore';
import { Link, useLocation } from '@remix-run/react';
import { motion } from 'framer-motion';
import { useEffect, useState } from 'react';
import ConnectButton from '@/components/topBar/ConnectButton';
import { Logo } from '@/components/Logo';

interface NavLinkProps {
  to: string;
  children: React.ReactNode;
  className?: string;
  target?: string;
  referrer?: string;
}

const LogoLink = ({ network }: { network: string }) => (
  <Link to="/" className=" 
  h-12 
  rounded-md
  cursor-pointer 
  flex 
  items-center 
  gap-0 min-[720px]:gap-3 
  font-semibold
  pl-1 pr-0 pt-2 pb-2
  transition-all duration-75 ease-in-out 
  bg-black hover:bg-neutral-800">
    <Logo color="white" className="
    h-12 w-[3.25rem] pb-2
    p-1 
    " />
    {network !== 'mainnet' && <span className="text-[8px] absolute text-white translate-y-4 translate-x-[11px] mt-1">{network.toUpperCase()}</span>}

    <div
      style={{ letterSpacing: '0.05em' }}
      className="flex flex-col gap-0 pr-2
      fira-code text-nowrap text-white
      overflow-hidden
      ">
      <span className="text-base" >EPHEMERA</span>
    </div>
  </Link>
);

const NavLink = ({ to, children, target, className }: NavLinkProps) => {
  const classes = `
    ${useLocation().pathname.includes(to) ? 'font-bold underline underline-offset-4' : ''} text-base
    max-[440px]:pt-2 text-white transition-colors hover:text-gray-200 hover:font-bold whitespace-nowrap
    ${className}
  `;

  return (
    <Link
      to={to}
      prefetch={target === '_blank' ? 'none' : 'viewport'}
      target={target}
      className={classes}>
      {children}
    </Link>
  );
}

const AuctionLink = ({ className }: { className?: string }) => {
  const { data: currentAuction } = useCurrentAuctionQuery();
  const auctionCycle = currentAuction?.auction_cycle;
  const auctionUrl = AuctionCycleSchema.safeParse(auctionCycle).success ? `/auction/${auctionCycle}` : '/auction';
  const classes = `
    ${useLocation().pathname.includes("auction") ? 'font-bold underline underline-offset-4' : ''} text-base
    max-[440px]:pt-2 text-white transition-colors hover:text-gray-200 hover:font-bold whitespace-nowrap
    ${className}
  `;

  return (
    <Link to={auctionUrl} className={classes}>
      Auction
    </Link>
  );
}

export function TopBar() {
  const [isDesktop, setIsDesktop] = useState(false);
  const network = useAccountStore(state => state.network);

  useEffect(() => {
    setIsDesktop(!isMobile());
  }, []);

  return (
    <motion.div layout layoutRoot layoutId='nav' key='nav'
      className="max-w-[100vw] fixed w-full 2 pt-2 pb-3 pr-4 mb-8 border-b-2 border-black z-[100] top-0 items-center content-stretch gap-0 bg-black"
    >

      <header className="grid grid-cols-12 h-10 justify-between gap-4 items-center" >

        <nav style={{ container: 'size' }} className="col-span-5 font-medium flex flex-row justify-stretch items-center gap-6 text-sm lg:gap-6">
          <LogoLink network={network} />
          <AuctionLink className='hidden sm:inline' />
          <NavLink className='hidden sm:inline' to="/collection">Collection</NavLink>
        </nav>

        <motion.div
          variants={{ hidden: { opacity: 0, }, visible: { opacity: 1 } }}
          initial='hidden'
          animate='visible'
          key='nav-controls'
          className="col-start-12 w-fit justify-self-end flex flex-row gap-4 items-center"
        >
          {isDesktop && network !== '' && <ConnectButton network={network} />}
        </motion.div>

      </header>
    </motion.div>
  );
}
