import { useSettingsStore } from '@/stores/settingsStore';
import { useAccountStore } from '@/stores/accountStore';
import type { LoaderFunction, LoaderFunctionArgs } from '@remix-run/cloudflare';
import {
  Links,
  Meta,
  Outlet,
  Scripts,
  ScrollRestoration,
  useLoaderData,
  useLocation,
  useRouteError,
} from "@remix-run/react";
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { useEffect, useRef } from "react";
import { TopBar } from "@/components/topBar/TopBar";
import { BottomBar } from './components/bottomBar/BottomBar';
import { Toaster } from "@/components/ui/sonner";
import { TooltipProvider } from "@/components/ui/tooltip";
import { SfxProvider } from "@/contexts/SfxContext";
import './globals.css';
import { getJwtDecoded } from "@/lib/getJwtDecoded";
import { getEnvVar } from "@/lib/agoraUrl";
import { getRefreshTokenDecoded } from "@/lib/withTokenRefresh";
import { useRefreshAuthMutation } from "@/queries/account";
import { Logo } from "@/components/Logo";
import { useAgoraWebsocket } from "@/queries/auction";
import { GlobalSoundHandler } from './components/GlobalSoundHandler';
import { AnimatePresence } from 'framer-motion';
import { StarfieldBackground } from './components/StarfieldBackground';
import { Wallet } from './schemas/bitcoin';
import { useJwtStore } from './stores/jwtStore';

interface LoaderData {
  network: "mainnet" | "signet" | "regtest";
  isAudioOn: boolean;
  isConnected: boolean;
  ordinalAddress: string;
  btcAddress: string;
  btcAddressPubKey: string;
  exp: number;
  wallet: Wallet;
  jwt: string | undefined;
}

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: 2,
      staleTime: 30 * 1000,
    },
  },
});

export const loader: LoaderFunction = async ({ request, context }: LoaderFunctionArgs) => {
  const network = getEnvVar(context, 'NETWORK');
  const cookieHeader = request.headers.get('Cookie');
  const cookies = new URLSearchParams(cookieHeader?.split('; ').join('&'));
  const isAudioOn = cookies.get('audioOn') === 'true' || false;
  const jwt = cookies.get('jwt') || undefined;
  const { ordinal_address: ordinalAddress, exp } = getJwtDecoded(request);
  const isConnected = ordinalAddress !== '';
  const { btc_address: btcAddress, btc_address_pub_key: btcAddressPubKey, wallet: wallet } = getRefreshTokenDecoded(request);
  return { jwt, isAudioOn, network, isConnected, wallet, ordinalAddress, btcAddress, btcAddressPubKey, exp };
}

export function Layout({ children }: { children: React.ReactNode }) {

  return (
    <html lang="en">
      <head>
        <meta charSet="utf-8" />
        <meta
          name="viewport"
          content={`width=device-width, initial-scale=1`}
        />
        <link rel="icon" href="/favicon-64x64.png" type="image/png" />

        <Meta />

        <link rel="preconnect" href="https://fonts.googleapis.com" />
        <link rel="preconnect" href="https://fonts.gstatic.com" crossOrigin={""} />
        <link href="https://fonts.googleapis.com/css2?family=Fira+Code:wght@300..700&family=Fira+Mono:wght@400;500;700&family=Fira+Sans+Condensed:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Fira+Sans+Extra+Condensed:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap" rel="stylesheet" />
        <link rel="manifest" href="/manifest.json" />
        <link rel="preconnect" href="https://fonts.googleapis.com" />
        <link rel="preconnect" href="https://fonts.gstatic.com" crossOrigin="anonymous" />
        <link href="https://fonts.googleapis.com/css2?family=Chakra+Petch:wght@0,300;0,400;0,500;0,600;0,700;1,300;1,400;1,500;1,600;1,700&display=swap" rel="stylesheet" />
        <link href="https://fonts.googleapis.com/css2?family=Fira+Mono:wght@400;500;700&display=swap" rel="stylesheet" />
        <Links />
      </head>

      <body
        className="fira-code-400 mb-12 dark"
      >
        <Toaster
          offset={16}
          expand={true}
          toastOptions={{
            duration: 1000,
            style: { marginBottom: '4rem' },
            classNames: {
              default: "border-gray-500 bg-muted/20 p-[2px] absolute",
              error: "border-red-600 bg-red-500 border",
              success: "border-green-600 bg-green-500 border",
              closeButton: 'bg-black hover:text-black',
            },
          }}
        />
        <SfxProvider>
          <QueryClientProvider client={queryClient}>
            <TooltipProvider>
              <TopBar />
              <BottomBar />
              <StarfieldBackground />
              {children}
              <ScrollRestoration />
              <Scripts />
            </TooltipProvider>
            <ReactQueryDevtools />
          </QueryClientProvider>
        </SfxProvider>
      </body>
    </html >
  );
}

const App = () => {
  const refreshAuthMutation = useRefreshAuthMutation();
  const {
    network,
    isAudioOn,
    isConnected,
    ordinalAddress,
    btcAddress,
    btcAddressPubKey,
    exp,
    wallet,
    jwt
  } = useLoaderData<LoaderData>();

  const setJwt = useJwtStore(state => state.setJwt);
  const clearJwt = useJwtStore(state => state.clearJwt);

  useEffect(() => {
    if (jwt) {
      setJwt(jwt);
    } else {
      clearJwt();
    }
  }, [jwt, setJwt]);

  const setNetwork = useAccountStore(state => state.setNetwork);
  const setWalletInfo = useAccountStore(state => state.setWalletInfo);
  const setIsConnected = useAccountStore(state => state.setIsConnected);
  const location = useLocation();
  const connectRef = useRef(false);
  const setAudioEnabled = useSettingsStore(state => state.setAudioEnabled);

  const _websocket = useAgoraWebsocket();

  useEffect(() => {
    setAudioEnabled(isAudioOn);
  }, [setAudioEnabled, isAudioOn])

  useEffect(() => {
    if (connectRef.current) return;
    connectRef.current = true;

    setNetwork(network);
    if (isConnected === null) {
      setIsConnected(false);
    }
  }, [isConnected, ordinalAddress, setWalletInfo, exp, btcAddress, btcAddressPubKey, network, setNetwork]);

  useEffect(() => {
    if (location.hash) {
      const el = document.querySelector(location.hash);
      if (el) {
        el.scrollIntoView();
      }
    }
  }, [location]);

  useEffect(() => {
    if (connectRef.current && isConnected == null) return;
    connectRef.current = true;

    if (isConnected === null) {
      setIsConnected(false)
    }

    if (isConnected && ordinalAddress && btcAddress && btcAddressPubKey) {
      if (exp && exp < Date.now() / 1000) {
        refreshAuthMutation.mutate(network);
      } else {
        setWalletInfo({ network, ordinalAddress, btcAddress, btcAddressPubKey, wallet });
      }
    }
  }, [isConnected, ordinalAddress, setWalletInfo, exp, btcAddress, btcAddressPubKey, network]);

  return (
    <>
      <div id='placeholder' className='h-12 bg-black' />
      <GlobalSoundHandler />
      <AnimatePresence mode='wait'>
        <Outlet />
      </AnimatePresence>
    </>
  )
}

export default App;

export function ErrorBoundary() {
  const error: any = useRouteError();

  if (error instanceof Error) {
    let stack = error.stack || '';
    const cleanStack = (stack: string) => {
      return stack.split('\n').map((line: string) => {
        if (line.includes('node_modules')) {
          return '';
        }

        // Use regex to clean any absolute paths to start from gallery/
        return line.replace(/(\s+at\s+.*?\s+\()?(.*?\/gallery\/.*?)(\))?$/g, (match, prefix, path) => {
          const cleanedPath = path.replace(/^.*?\/gallery\//, 'gallery/');
          return prefix ? `${prefix}${cleanedPath})` : cleanedPath;
        });
      }).filter(Boolean).join('\n');
    }
    stack = cleanStack(stack);

    return (
      <div className="mt-32 px-16 text-lg">
        <h1>Ephemera has a problem.</h1>
        <Logo size={120} className="mt-4 mx-auto h-200" />
        {stack
          ? <p className="text-wrap break-words">{stack}</p>
          : <p>{error.name}: {error.message}</p>
        }
      </div>
    );
  }

  return null;
}
